import $ from 'jquery';
import slick from 'slick-slider'


$(function () {
    console.log('ready');


    var height=$('header').innerHeight();
    console.log(height);
    $('.header-placeholder').height(height);

    $('nav .header--main-nav-list .nav-section a').on('click', function (event) {
      event.preventDefault();

      var targetSection = $($(this).attr('href'));

        $('html, body').animate({
            scrollTop: targetSection.offset().top - 100
        }, 800);
     
      
  });

    $('.banner--slider').slick({
      dots: false,
      arrows :true,
      infinite: false,
      rows : 0,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    })

  

    $(".doctor--section-listing").slick({
      arrows: false,
      dots: false,
      infinite: false,
      rows : 0,
      responsive: [
        {
          breakpoint: 99999,
          settings: "unslick",
        },
        {
          breakpoint: 640,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1.3,
            dots: false,
            arrows: false,
          },
        },
      ],
    });

    $(".blogs--section-listing").slick({
      arrows: false,
      dots: false,
      infinite: false,
      rows : 0,
      responsive: [
        {
          breakpoint: 99999,
          settings: "unslick",
        },
        {
          breakpoint: 640,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1.2,
            dots: false,
            arrows: false,
          },
        },
      ],
    });

    

    $(".nav-list .list-items a").each(function () {
      if ($(this).attr("href") === path) {
        $(".nav-list .list-items").removeClass("active");
        $(this).parent().addClass("active");
      }
    });

    $('[data-path]').each(function () {
        let totPaths = parseInt($(this).attr('data-path'), 10),
            i = 0;
        for (let i = 1; i < totPaths + 1; i++) {
            $(this).append('<span class="path' + i + '"></span>');
        }
    });

    $('.nh--hamburger').on('click', function(){
      $('.header--main-nav-list').addClass('active')
      $('.close').addClass('close-btn')
      $('.header-overlay').addClass('open');
      $('body').addClass('block');
    })

    $('.close, .header--main-nav-list li, .header-overlay').on('click',function (e){
      $('.header--main-nav-list').removeClass('active')
      $('.close').removeClass('close-btn')
      $('.header-overlay').removeClass('open');
      $('body').removeClass('block');
    })

    // $('.header-overlay').on("click",function(){
    //   $('.header--main-nav-list').removeClass('active')
    //   $('.close').removeClass('close-btn')
    //   $('.header-overlay').removeClass('open');
    //   $('body').removeClass('block');
    // })

   
 // zoom pinch on ios
 var isiDevice = / iPod| iPad| iPhone/i.test(
  navigator.userAgent.toLowerCase()
);
if (isiDevice) {
  document.addEventListener(
    "touchmove",
    function (event) {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );
}
   

$('[data-modal-open]').on('click', function () {
  let modalId = $(this).data('modal-open');
  openModal(modalId);
});

$('.modal--close, .overlay').on('click', function () {
  $('.modal, .overlay').removeClass('show');
  $('body').removeClass('block');
});

$('.modal--close, .overlay').on('click', function () {
  $('.drawer, .overlay').removeClass('show');
  $('body').removeClass('block');
});


function openModal(modalId) {
  $('body').addClass('block');
  $('[data-modal-id]').removeClass('show')
  $('[data-modal-id="' + modalId + '"], .overlay').addClass('show')
}



});



/* ---------- document ready end ---------- */
